import styled, { css } from 'styled-components'
import { isMobile } from '../../../lib/screenHelpers'

export const AddressCardContainer = styled.div`
  position: relative;
  padding: 20px;
  margin: 18px auto;
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.1);
`

export const AddressCard = styled.div`
  display: flex;
`

export const AddressIcon = styled.div`
  padding-top: 2px;
`

export const AddressContent = styled.div`
  width: 100%;
  margin-left: 24px;
`

export const AddressInfo = styled.div`
  p {
    font-size: 14px;
    line-height: 20px;
    margin: 2px 0;
  }
`

export const Name = styled.p`
  font-weight: bold;
  word-break: break-all;
`

export const DefaultAddressContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-self: flex-start;
  margin-left: 16px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      position: relative;
      top: 3px;
      right: 0px;
      left: 36px;
      margin-left: 0px;
      width: 100%;
    `}
`

export const DefaultAddress = styled.span`
  color: #007d48;
  font-weight: bold;
  font-size: 14px;
`

export const Company = styled.p`
  font-weight: bold;
  word-break: break-all;
`

export const Street1 = styled.p`
  word-break: break-all;
`

export const WithDefaultAddress = styled.div`
  display: flex;
  justify-content: space-between;
`
