import React from 'react'
import Lottie from 'lottie-react'
import lottieSpinner from './lottieSpinner.json'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useGetText, useShippingFormState } from '../../hooks'
import * as Styled from './styles'

export function Spinner(): JSX.Element {
  const getText = useGetText()
  const { isHpx } = useShippingFormState()

  if (isHpx) {
    return (
      <Styled.LoadingHpx>
        <Styled.LottieSpinner data-testid="lottie-spinner">
          <Lottie animationData={lottieSpinner} loop autoplay />
        </Styled.LottieSpinner>
        <span>{getText('shipping_form.loading')}</span>
      </Styled.LoadingHpx>
    )
  }

  return (
    <Styled.Loading data-testid="spinner">
      <ProgressIndicator />
      <span>{getText('shipping_form.loading')}</span>
    </Styled.Loading>
  )
}
