import styled, { css } from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Select from '@veneer/core/dist/scripts/select'
import Checkbox from '@veneer/core/dist/scripts/checkbox'
import { isMobile } from '../../lib/screenHelpers'
import { ContainerSize } from '../../types'

export const ShippingContent = styled.form<{
  $enableShippingAutofill: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: auto;

  h4 {
    margin: 0 0 24px 0;
  }

  ${({ $enableShippingAutofill }) =>
    $enableShippingAutofill &&
    css`
      justify-content: space-between;
      height: 100%;
    `}
`

export const AddPhoneNumber = styled.p`
  font-size: 16px;
  margin: 0 0 16px;
`

export const ShippingFields = styled.div<{
  $forceFieldsFormat?: ContainerSize
}>`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${({ theme: { containerSize }, $forceFieldsFormat }) => {
    const isForcingMobile = $forceFieldsFormat && isMobile($forceFieldsFormat)
    const isForcingNotMobile =
      $forceFieldsFormat && !isMobile($forceFieldsFormat)
    return (
      (isForcingMobile || (isMobile(containerSize) && !isForcingNotMobile)) &&
      css`
        width: 100%;
        flex-direction: column;
        align-items: center;
      `
    )
  }}
`

export const LeftCol = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        margin-right: 0;

        p {
          margin-top: 0;
        }
      `}
  }
`

export const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InputField = styled(TextBox)<{
  error?: boolean
  $isHpx?: boolean
}>`
  margin: 0 0 12px 0;
  width: 100%;

  > div:first-child {
    margin-top: 0;
  }

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      gap: 16px;
    `}

  > div {
    background-color: white;
    ${({ error, $isHpx }) =>
      error
        ? css`
            border-color: ${$isHpx && '#ff5c6c'};
          `
        : css`
            border-color: inherit;
          `}
  }
`

export const TextMessageOptIn = styled(Checkbox)`
  align-self: flex-start;
  margin: 0 0 16px 0;

  #text-message-optin > span {
    overflow-wrap: break-word;
  }

  > span {
    font-size: 16px;
    word-break: break-word;
  }

  ${({ theme: { containerSize } }) =>
    !isMobile(containerSize) &&
    css`
      max-width: 52%;
    `}
`

export const StateDropdown = styled(Select)`
  width: 100%;
  margin-bottom: 12px;

  > div {
    background-color: white;
  }
`

export const InputGroup = styled.div<{ $forceFieldsFormat?: ContainerSize }>`
  ${({ theme: { containerSize }, $forceFieldsFormat }) => {
    const isForcingMobile = $forceFieldsFormat && isMobile($forceFieldsFormat)
    const isForcingNotMobile =
      $forceFieldsFormat && !isMobile($forceFieldsFormat)

    if (isForcingNotMobile || (!isMobile(containerSize) && !isForcingMobile)) {
      return css`
        width: 100%;
        display: flex;

        & > :first-child {
          padding-right: 16px;
        }
      `
    }
  }}
`

export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  font-size: 16px;

  svg {
    margin-right: 8px;
  }
`

export const ShippingButtons = styled.div<{
  $enableShippingAutofill: boolean
  spaceBetween?: boolean
}>`
  margin: 32px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${({ $enableShippingAutofill }) =>
    $enableShippingAutofill &&
    css`
      margin-bottom: 0;
    `}

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;
      flex-direction: column-reverse;

      > div:last-child:not(:only-child) {
        margin-bottom: 16px;
      }
    `}

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`

export const LeftButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      flex-direction: column-reverse;

      > button {
        width: 100%;
      }
    `}
`

export const RightButtons = styled.div<{
  $shouldWrapButtons: boolean
  $enableShippingAutofill: boolean
  $hasSkipButton: boolean
}>`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${({
    $enableShippingAutofill,
    theme: { containerSize },
    $shouldWrapButtons,
    $hasSkipButton
  }) => {
    if ($enableShippingAutofill) {
      return css`
        display: flex;
        justify-content: flex-end;
        width: 100%;

        & button {
          width: fit-content;
        }

        ${(($shouldWrapButtons && window.innerWidth < 866) ||
          window.innerWidth < 674) &&
        css`
          flex-direction: column-reverse;

          & button {
            width: 100%;
            white-space: normal;
          }
        `}

        @media (min-width: 674px) and (max-width: 865px) {
          justify-content: ${$hasSkipButton ? 'space-between' : 'flex-end'};
        }
      `
    } else if (isMobile(containerSize))
      return css`
        display: flex;
        flex-direction: column-reverse;

        > button {
          width: 100%;
        }
      `
  }}
`

export const SecondaryButton = styled.div`
  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;

      & button {
        width: 100%;
      }
    `}
`

export const RefuseButton = styled.div<{ $shouldWrapButtons: boolean }>`
  & button {
    bottom: auto;
    left: auto;
    position: relative;
    max-width: none;
  }

  ${({ $shouldWrapButtons }) =>
    (($shouldWrapButtons && window.innerWidth < 866) ||
      window.innerWidth < 674) &&
    css`
      width: 100%;

      & div > button {
        width: 100%;
      }
    `}
`

export const CodeButton = styled(Button)<{ $secondaryButton?: boolean }>`
  ${({ $secondaryButton }) =>
    $secondaryButton &&
    css`
      margin-top: 16px;
    `}

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;
    `}
`
