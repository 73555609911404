import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  div[role=presentation]:not(:focus-within) ul[role=listbox]{
    transition: 0.2s ease-in-out;
    z-index: 1399 !important;
  }

  ul[role=listbox] {
    z-index: 1700 !important;
  }
  
  input {
    &:disabled {
      -webkit-text-fill-color: $color-gray-4;
      opacity: 1;
    }
  }

`
